import { Button } from "@mui/material";
import { Col, Row } from "antd";
import React, { useRef } from "react";
import { DescriptionText } from "../componentsxx/DescriptionText";
import { quickLinksType } from "../componentsxx/Footer";
import Layout from "../componentsxx/Layout";
import { scrollToComponent } from "../componentsxx/LinkElement";
import { navMenuDataT } from "../componentsxx/NavMenu";
import SectionHeaderContainer, { SectionHeaderH1Tag } from "../componentsxx/SectionTitle";
import { SEO } from "../componentsxx/SEO";
import { pageStyles } from "../styles/componentStyles";
import { textObj } from "../text/text";

const { headerText, subText } = textObj.aboutUsSection.stub;

const AboutUsBody = () => {
  return (
    <>
      <Row
        justify="center"
        style={{
          paddingTop: 64,
          paddingBottom: 64,
          paddingLeft: 64,
          paddingRight: 64,
        }}
      >
        <Col span={24}>
          <SectionHeaderContainer>
            <SectionHeaderH1Tag headerText={headerText} />
          </SectionHeaderContainer>
        </Col>

        <Col span={24}>
          {/* <HeaderText headerText={headerText} /> */}
          <DescriptionText descriptionText={subText} />
        </Col>

        <Row
          //unclear why row needed, but only way to get button centered
          style={{
            paddingTop: 20,
          }}
        >
          <Col
            span={24}
            style={{
              color: "black",
              borderColor: "black",
              borderRadius: 0,
              letterSpacing: "4px",
              fontSize: "14px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "black",
                borderColor: "black",
                borderRadius: 0,
                letterSpacing: "4px",
                fontSize: "14px",
              }}
            >
              Work with Us
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

const AboutUsPage = () => {
  //Header Button:
  const contactUsRef = useRef(null); //init refs
  const contactUsOnClick = () => scrollToComponent(contactUsRef);
  const headerButton: navMenuDataT["headerButton"] = {
    headerText: textObj.navMenu.headerButton.headerText,
    onClick: contactUsOnClick,
    ref: contactUsRef,
  };
  //Non-Header Buttons:
  const homeRef = useRef(null);
  //const homeOnClick = () => scrollToComponent(homeRef);

  const menuOptsWithOnClick: navMenuDataT["menuOptions"] = [
    {
      text: "Home",
      //onClick: homeOnClick,
      toInternalLink: "/", //homepage is /
    },
  ];
  const footerData: quickLinksType[] = []; //empty quick links

  return (
    <main style={pageStyles}>
      <Layout
        homeRef={homeRef}
        headerButton={headerButton}
        menuOptions={menuOptsWithOnClick}
        footerData={footerData}
        contactUsRef={contactUsRef}
      >
        <AboutUsBody />
      </Layout>
    </main>
  );
};

export default AboutUsPage;
export const Head = () => (
  <SEO
    title="T.A. Industries - About T.A. Industries, Our Culture and Company News"
    description="Stay up to date with T.A. Industries company news and services. Discover stories about our culture, philosophy, and how T.A. Industries services is impacting others."
  />
);
